import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Auth from 'components/Auth';
import Layout from 'components/Order/Layout';
import Input from 'components/UI/Input';
import InputAddress from 'components/UI/Input/InputAddress';
import { RadioGroup } from 'components/UI/Radio';
import GoogleMapWithStore from 'components/GoogleMap/GoogleMapWithStore';
import ModalAddress from 'components/UI/Modal/ModalAddress';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import TitleName from 'components/UI/Header/TitleName';
import _, { set } from 'lodash';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { PATH_RECEIVER } from 'utils/constants/paths';
import { updateDispatchData, getDispatchData } from 'utils/_dispatch.js';

const Container = styled.div``;

const StyledInputAddress = styled(InputAddress)`
  margin-bottom: 10px;
`;

const ElevatorText = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
`;

const Btn = styled.div`
  width: 114px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    flex: 1;
    height: 60px;
    background-color: #5fd2da;
    border: 1px solid #5fd2da;
    border-radius: 40px;
    font-size: 20px;
    color: #fff;
  }
`;

const Card = styled.div`
  padding: 16px 12px;
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 0px;

  @media (max-width: ${md}) {
    padding: 16px 12px 130px;
    border-radius: 0;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 345px;
  padding-bottom: 60px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const FormItem = styled.div`
  width: 100%;

  &.name,
  &.phone {
    width: calc((100% - 10px) / 2);

    @media (max-width: ${md}) {
      width: 100%;
    }
  }
`;

const FormItemLabel = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 8px;
`;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 108px;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    z-index: 1000;
    padding: 24px 15px;
  }
`;

const Title = styled.div`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    flex: 1;
  }
`;

const elevatorList = [
  { label: '有', value: '1' },
  { label: '無', value: '0' },
];

const ReceiverDetail = (props) => {
  const { mode, location } = props;
  const isEdit = mode === 'edit';
  const pageTitle = isEdit ? '收件人管理  編輯' : '收件人管理';
  const api = useAPI();
  const loading = useLoading();
  const userInfo = useSelector((state) => getters.getUserInfo(state));

  const [selectedStore, setSelectedStore] = useState();
  const [selectedPrevStore, setSelectedPrevStore] = useState();
  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [district, setDistrict] = useState('');
  const isDefault = true;
  const [addressDetail, setAddressDetail] = useState({});
  const [fullMapLatLng, setFullMapLatLng] = useState({
    lat: 25.04776,
    lng: 121.53185,
  });
  const [modalAddress, setModalAddress] = useState(false);
  const [isCurrentUse, setIsCurrentUse] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState('');

  const [postalCode_3, setPostalCode_3] = useState('');

  //取的收件人詳細
  const getAddressDetail = (addressId) => {
    loading.show();
    api
      .getAddressDetail(addressId)
      .then((res) => {
        setAddressDetail({
          ...res,
          address: res.adjustedAddress,
          deliveryIsElevator: res.deliveryIsElevator ? '1' : '0',
        });

        //判斷是否為當前的使用地址
        const dispatchData = getDispatchData();
        const currentId = _.get(dispatchData, 'addressId', '');
        console.log('currentId', currentId);
        console.log('addressId', addressId);
        if (currentId === addressId) {
          setIsCurrentUse(true);
        }
        setCurrentStoreId(res.defaultStoreId);
        getStoreList(res.address, res.defaultStoreId);

        setPostalCode_3(res.postalCode_3);
      })
      .catch((error) => {
        loading.dismiss();
        console.log('error', error);
      });
  };

  //取得收件方式
  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        const updatedSignatureList = res.map((item) => {
          return {
            ...item,
            label: item.name,
          };
        });
        setReceiveTypeOptions(updatedSignatureList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //取得門店列表
  const getStoreList = (address, storeId) => {
    api
      .getStoreByLocation(null, null, address)
      .then((res) => {
        if (!res) {
          setAddressDetail((prevState) => ({
            ...prevState,
            address: '',
          }));
          return;
        }
        console.log('getStoreByLocation', res);
        setStoreList(res.rows);
        setDistrict(res.query.district);

        // 3碼郵遞區號
        if (!isEdit) setPostalCode_3(res.query.postalCode_3);

        setFullMapLatLng({
          lat: res.query.latitude,
          lng: res.query.longitude,
        });
        if (res.rows.length > 0) {
          let result = res.rows.find((item) => {
            return item.id === storeId;
          });
          if (result) {
            setSelectedStore(result);
          } else {
            setSelectedStore(res.rows[0]);
          }
        }
        loading.dismiss();
      })
      .catch((error) => {
        loading.dismiss();
        console.log(error);
      });
  };

  const onSelectStore = (store) => {
    setSelectedPrevStore(selectedStore);
    setSelectedStore(store);

    console.log('store', store);
  };

  const phoneFormatValid = (phone) => {
    if (phone === undefined || phone === '') {
      return false;
    }

    var mobileRegex = /^09\d{8}$/;

    return mobileRegex.test(phone);
  };

  //更新收件人資料
  const onClickUpdateAddress = (addressData) => {
    if (!addressData.fullName) {
      alert('請填寫姓名');
      return;
    }
    if (!addressData.phone) {
      alert('請填寫手機號碼');
      return;
    }
    if (!phoneFormatValid(addressData.phone)) {
      alert('手機號碼格式錯誤');
      return;
    }
    if (!addressData.address) {
      alert('請填寫地址');
      return;
    }
    const parameters = {
      ...addressData,
      deliveryIsElevator: addressData.deliveryIsElevator === '1' ? true : false,
      isDefault: isDefault,
      defaultStoreId: selectedStore.id,
      defaultStoreName: selectedStore.name,
      district: district,
      postalCode_3: postalCode_3,
    };

    if (isEdit) {
      //如果是現在使用的地址 更換門店要打更換門店api
      if (isCurrentUse) {
        const dispatchData = getDispatchData();
        const dispatchStoreId = _.get(dispatchData, 'storeId', '');
        const isChangeStore =
          selectedStore.id !== dispatchStoreId && dispatchStoreId !== '';

        console.log('isChangeStore', isChangeStore);
        if (isChangeStore) {
          confirm(
            '切換出貨地址或選擇其他門店，原購物車內商品將會清除',
            '更換門店'
          )
            .then((res) => {
              api
                .changeStore({ storeId: selectedStore.id })
                .then((res) => {
                  updateAddress(parameters);
                })
                .catch((error) => {});
            })
            .catch(() => {
              //如果按取消 回上一個門店
              setSelectedStore(selectedPrevStore);
            });

          return;
        }
      }

      updateAddress(parameters);
    } else {
      addNewAddress(parameters);
    }
  };

  const updateAddress = (parameters) => {
    api
      .updateAddress(parameters)
      .then((res) => {
        if (!res) return;
        if (isCurrentUse) {
          updateDispatchData('storeId', parameters.defaultStoreId);
          updateDispatchData('storeName', parameters.defaultStoreName);
          updateDispatchData('receiverName', parameters.fullName);
          updateDispatchData('receiverPhone', parameters.phone);
          updateDispatchData('receiverAddress', parameters.address);
          updateDispatchData(
            'receiverElevator',
            parameters.deliveryIsElevator === false ? '0' : '1'
          );
          updateDispatchData('receiverReceive', parameters.deliveryPointValue);
          updateDispatchData('floor', parameters.floor);
          updateDispatchData('receiverAddressRemark', parameters.deliveryNote);
        }
        alert('更新成功', () => {
          navigate(PATH_RECEIVER);
        });
      })
      .then((error) => console.log(error));
  };

  const addNewAddress = (parameters) => {
    api
      .newAddress(parameters)
      .then((res) => {
        if (!res) return;
        alert('新增成功', () => {
          navigate(PATH_RECEIVER);
        });
      })
      .catch((error) => console.log(error));
  };
  const handleAddressChange = (newAddress) => {
    setStoreList([]);
    setModalAddress(false);
    api.addrStandardization(newAddress).then((res) => {
      if (res.length > 0) {
        setAddressDetail((prevState) => ({
          ...prevState,
          address: res[0].adjusted_address,
          floor: res[0].floor,
        }));
        getStoreList(res[0].adjusted_address);
      }
    });
  };

  useEffect(() => {
    isEdit
      ? getAddressDetail(location.state.addressId)
      : setAddressDetail((prevState) => ({
          ...prevState,
          fullName: userInfo.name,
          phone: userInfo.mobile,
          deliveryIsElevator: '0',
          deliveryPointValue: '5',
        }));
    getOptions();
    setStoreList([]);
  }, []);

  return (
    <Layout {...props} pageTitle={pageTitle}>
      <TitleName />
      <Auth />
      <Container>
        <TitleBar>
          <Title>{pageTitle}</Title>
          <BtnGroup>
            <Btn
              onClick={() =>
                onClickUpdateAddress({
                  ...addressDetail,
                  address:
                    addressDetail.floor === '' ||
                    addressDetail.floor === undefined
                      ? addressDetail.address
                      : `${addressDetail.address}${addressDetail.floor}`,
                })
              }
            >
              {isEdit ? '修正' : '新增'}
            </Btn>
          </BtnGroup>
        </TitleBar>
        <Card>
          <MapWrapper>
            <GoogleMapWithStore
              location={fullMapLatLng}
              storesPerView={5}
              spaceBetweenStore={16}
              selectedStore={selectedStore}
              onSelectStore={onSelectStore}
              storeList={storeList}
            />
          </MapWrapper>
          <FormItem className="name">
            <FormItemLabel>收件人</FormItemLabel>
            <Input
              value={addressDetail.fullName}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  fullName: e.target.value,
                }))
              }
              placeholder="請輸入姓名"
            />
          </FormItem>
          <FormItem className="phone">
            <FormItemLabel>電話</FormItemLabel>
            <Input
              value={addressDetail.phone}
              maxLength={10}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  phone: e.target.value,
                }))
              }
              placeholder="請輸入電話"
            />
          </FormItem>
          <FormItem>
            <FormItemLabel>地點</FormItemLabel>
            <StyledInputAddress
              onClick={() => {
                setModalAddress(true);
              }}
              value={addressDetail.address}
            />
            <Input
              value={addressDetail.floor}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  floor: e.target.value,
                }))
              }
              placeholder="樓層或房號"
            />
          </FormItem>
          <FormItem>
            <FormItemLabel>地址暱稱</FormItemLabel>
            <Input
              value={addressDetail.tag}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  tag: e.target.value,
                }))
              }
              placeholder="地址標籤"
            />
          </FormItem>
          <FormItem>
            <FormItemLabel>電梯</FormItemLabel>
            <RadioGroup
              name="elevator"
              defaultValue={addressDetail.deliveryIsElevator}
              value={addressDetail.deliveryIsElevator}
              list={elevatorList}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  deliveryIsElevator: e.target.value,
                }))
              }
            />
          </FormItem>
          <FormItem>
            <FormItemLabel>簽收</FormItemLabel>
            <RadioGroup
              name="signature"
              list={receiveTypeOptions}
              align="vertical"
              defaultValue={addressDetail.deliveryPointValue}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  deliveryPointValue: e.target.value,
                }))
              }
            />
            <ElevatorText>
              如果您的樓層有安全管制，請通知管理室協助簽收。
            </ElevatorText>
          </FormItem>
          <FormItem>
            <FormItemLabel>地址備註</FormItemLabel>
            <Input
              value={addressDetail.deliveryNote}
              onChange={(e) =>
                setAddressDetail((prevState) => ({
                  ...prevState,
                  deliveryNote: e.target.value,
                }))
              }
              placeholder="樓層/房號、公司大樓名稱、其他地址備註"
            />
          </FormItem>
        </Card>
      </Container>
      <ModalAddress
        open={modalAddress}
        onClose={() => {
          setModalAddress(false);
        }}
        handleAddressChange={(newAddress) => {
          handleAddressChange(newAddress);
        }}
        receiverAddress={addressDetail.address}
      />
    </Layout>
  );
};

export default ReceiverDetail;
