import React from 'react';
import styled from 'styled-components';

import Input from 'components/UI/Input';

import IconAddress from 'images/guest-mode-address-map.svg';
import IconArrow from 'images/arrow-2.svg';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    background-image: url(${IconAddress});
    width: 22px;
    height: 24px;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    background-image: url(${IconArrow});
    width: 22px;
    height: 24px;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledInput = styled(Input)`
  padding: 14px 37px;
  cursor: pointer;
`;

const InputAddress = (props) => {
  const { className, value, onChange, onClick } = props;
  return (
    <InputWrapper className={className} onClick={onClick}>
      <StyledInput
        readOnly
        placeholder="請輸入地址"
        value={value}
        onChange={onChange}
      />
    </InputWrapper>
  );
};

export default InputAddress;
